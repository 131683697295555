import logo from '../../assets/themes/icons/favicon.svg';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import CartComponent from "../cart/CartComponent";
import {useDispatch, useSelector} from "react-redux";
import {Badge} from "antd";
import "./Header.css"
import {Helmet} from "react-helmet";
import {CLIENT_URL} from "../../configs/url";
import {changeActiveSearch, changeDialogCart} from "../../layouts/mainlayout/MainLayout.actions";

const HeaderDefaultComponent = ({categoryList, searchButton, loading, setChangeSide}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const cartReducerInfo = useSelector(state => state.cartReducer);
  const mainReducerInfo=useSelector(state=>state.mainReducer);
  const [cartItem, setCartItem] = useState([cartReducerInfo?.cartItem]);
  const [totalPriceCart, setTotalPriceCart] = useState(0);
  useEffect(() => {
    setCartItem(cartReducerInfo?.cartItem.map(index => {
      const totalPrice = index?.aboutSize.price * index?.quantity;
      return {...index, totalPrice: totalPrice}
    }));
    const temp = cartReducerInfo?.cartItem.map(index => {
      return index?.aboutSize.price * index?.quantity
    })
    setTotalPriceCart(temp.reduce((previousScore, currentScore) => previousScore + currentScore, 0))
  }, [cartReducerInfo?.cartItem]);


  const checkOutButton = () => {
    dispatch(changeDialogCart(false))
    navigate("/order");
  }
  return (
      <header className="clearfix">
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{`DANH MỤC SẢN PHẨM - SHOEDOG - Shop giày uy tín nhất TP.HCM`}</title>
          <link
              rel="canonical"
              href={CLIENT_URL}
              title={`danh mục sản phẩm - Shop giày uy tín nhất TP.HCM »`}
          />
          <meta
              name="description"
              content={`Danh mục sản phẩm. Shop giày uy tín bậc nhất TP.HCM. Chuyên hàng 2hand, hàng New chính hãng 100%. Bán giày không bán lương tâm. Chất lượng là số 1.`}
          />
        </Helmet>
        <a id="togger__menu" onClick={() => {
          setChangeSide(true)
          dispatch(changeDialogCart(false))
        }}>
          <span></span>
          <span></span>
        </a>
        <div className="container">
          <div className="header__left">
            <a style={{display: "none"}}>SELL</a>
            <a style={{display: "none"}}>CONSIGNMENT MANAGER</a>
          </div>
          <div className="header__right">
            <a className="header__right--mbsearch" style={{display: "inline-block"}}
               onClick={() => searchButton()}><span
                className="icon-search"></span></a>
            <div className="header__cart dropdown">
              <a className={mainReducerInfo?.dialogCart === true ? "val-selected active" : "val-selected"}
                 onClick={() => {
                   dispatch(changeDialogCart(!mainReducerInfo?.dialogCart))
                 }}>

                <Badge size={"small"} count={cartItem?.length} offset={[8, 1]}>
                  <span className="icon-slice8"></span>
                </Badge>
                <span className={cartItem?.length === 0 ? "header__cart--num hide" : "header__cart--num"}></span>
              </a>
              <div className={mainReducerInfo?.dialogCart === true ? "dropdown-up-style active" : "dropdown-up-style hide"}>
                <div className="dropdown__inner">
                  <h2 className="text-uper font-700 fs-16">Giỏ hàng của bạn</h2>
                  {cartItem.length === 0 ?
                      <div className="text-center">
                        <p className="font-500 fs-15 mgB-15">Không có sản phẩm nào !</p>
                      </div>
                      :
                      <>
                        {cartItem.map(index => (
                            <CartComponent img={index?.detailProduct?.ImageMain.toString().replace("public", "private")}
                                           productName={index?.detailProduct?.ProName}
                                           quantity={index?.quantity}
                                           price={index?.totalPrice} size={index?.aboutSize?.size} index={index}
                                           />
                        ))}
                        <hr/>
                        <div className="clearfix mgB-20">
                          <span className="text-uper fs-14 font-600">Tổng giỏ hàng</span>

                          <p className="pull-right product-price font-600">{totalPriceCart?.toLocaleString('it-IT', {
                            style: 'currency',
                            currency: "VND"
                          })}</p>
                        </div>
                        <div className="text-center">
                          <p className="font-500 fs-15 mgB-15">Phí vận chuyển và thuế sẽ tính lúc thanh toán</p>
                          <a onClick={checkOutButton} className="text-uper btn-checkout ">Thanh toán</a>
                        </div>
                      </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="text-center header__logo" onClick={() => {
            setChangeSide(false)
            dispatch(changeActiveSearch(false))
            dispatch(changeDialogCart(false))
          }}>
            <span>logo</span>
            <a onClick={() => navigate("/")}><img src={logo} alt="DANH MỤC SẢN PHẨM - SHOEDOG - Shop giày uy tín nhất TP.HCM"/></a>
          </div>
        </div>
        <div className="container" style={{padding: "1% 10% 0 10%",}} onClick={() => {
          dispatch(changeDialogCart(false))
          dispatch(changeActiveSearch(false))
        }}>
          <div id="menu" >
            <div className="inner-menu">
              <div>
                <ul >

                  {categoryList?.map((value, index) => {
                        let isSub = value?.ListCategory.length === 0 ? false : true
                        return (
                            <li key={value?.ParentId} >
                              <a onClick={() => {
                                if (value?.ParentId === -1) {
                                  navigate('/new-product')
                                } else if (value?.ListCategory.length === 0) {
                                  navigate(`/product/${value?.ParentId}?page=1`)
                                }
                                else {
                                  if(value?.ParentId == 1) {
                                    navigate(`/product/6?page=1`)
                                  }
                                  if(value?.ParentId == 2) {
                                    navigate(`/product/11?page=1`)
                                  }
                                  if(value?.ParentId == 3) {
                                    navigate(`/product/3?page=1`)
                                  }
                                  if(value?.ParentId == 4) {
                                    navigate(`/product/7?page=1`)
                                  }
                                }
                              }}><span>{value?.ParentName}</span>
                              </a>
                              <div className={isSub? "menu__sub": ""} style={{marginTop: "0.8%"}}>
                                <ul>
                                  {
                                    value?.ListCategory?.map((cate, index) => {
                                      return (
                                          <li key={index} onClick={() => navigate(`/product/${cate?.CatId}?page=1`)}>
                                            <a>{cate?.CatName}</a>
                                          </li>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                            </li>
                        )
                      }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
  )
}

export default HeaderDefaultComponent