import * as types from "./MainLayout.constraints"
import produce from "immer"

const initialState = {
    isLoading: false,
    filterProduct: false,
    dialogCart: false,
    dialogSearchProduct: false,
}
export const MainLayoutReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case types.CHANGE_IS_LOADING:
                draft.isLoading = !draft.isLoading;
                break;
            case types.TURN_ON_IS_LOADING:
                draft.isLoading = true;
                break;
            case types.TURN_OFF_IS_LOADING:
                draft.isLoading = false;
                break;
            case types.CHANGE_TOAST_CART_PRODUCT:
                console.log(action.payload)
                draft.dialogCart = action.payload
                break;
            case types.CHANGE_ACTIVE_FILTER_PRODUCT:
                draft.filterProduct = action.payload
                break;
            case types.CHANGE_ACTIVE_SEARCH_PRODUCT:
                draft.dialogSearchProduct = action.payload
                break;
            default:
                return state;
        }
    })

