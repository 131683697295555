import * as types from "./MainLayout.constraints"


export const changeStateLoading = () => ({
  type: types.CHANGE_IS_LOADING
})

export const turnOffLoading = () => ({
  type: types.TURN_OFF_IS_LOADING
})


export const turnOnLoading = () => ({
  type: types.TURN_ON_IS_LOADING
})


export const changeActiveFilter = (payload) => ({
  type: types.CHANGE_ACTIVE_FILTER_PRODUCT,
  payload
})

export const changeDialogCart = (payload) => ({
  type: types.CHANGE_TOAST_CART_PRODUCT,
  payload
})

export const changeActiveSearch = (payload) => ({
  type: types.CHANGE_ACTIVE_SEARCH_PRODUCT,
  payload
})

